
import OrgSelect from '@/components/header/OrgSelect.vue';
import DefaultNavbar from '@/components/header/DefaultNavbar.vue';
import { defineComponent } from 'vue';
import { useAuth } from '@/composables/useAuth';
export default defineComponent({
  name: 'DefaultHeader',
  components: {
    OrgSelect,
    DefaultNavbar,
  },
  setup() {
    const { isVAR, isSuperAdmin } = useAuth();

    return {
      isVAR,
      isSuperAdmin,
    };
  },
});
