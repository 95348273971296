
import { defineComponent } from 'vue';
import { useAuth } from '@/composables/useAuth';

export default defineComponent({
  name: 'DefaultNavbar',
  setup() {
    const { isVAR, isSuperAdmin, logout, account } = useAuth();

    // if (!account.value) throw new Error('[NAVBAR] account is null');

    return { isVAR, isSuperAdmin, logout, account };
  },
});
