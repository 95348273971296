import { OrganizationsModule } from '@/store/organizations';
import { computed } from '@vue/runtime-core';

export function useOrgManager() {
  return {
    activeOrgIds: computed({
      get: () => OrganizationsModule.activeOrgIds,
      set: ids => OrganizationsModule.SET_ACTIVE_ORG_IDS(ids),
    }),
    orgs: computed(() => OrganizationsModule.orgs),
    orgMap: computed(() => OrganizationsModule.orgMap),
    activeOrgs: computed(() => OrganizationsModule.activeOrgs),
    fetchState: computed(() => OrganizationsModule.fetchState),
    selectAllOrgs: () => OrganizationsModule.SET_ALL_ACTIVE_ORGS(),
    deselectAllOrgs: () => OrganizationsModule.CLEAR_ACTIVE_ORGS(),
  };
}
