import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultHeader = _resolveComponent("DefaultHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_el_main = _resolveComponent("el-main")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_DefaultHeader),
    _createVNode(_component_el_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ], 64))
}