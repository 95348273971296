
import { defineComponent } from 'vue';
import { useOrgManager } from '../../composables/useOrgManager';
import { FetchStates } from '../../helpers/fetch-states';
import { OrganizationsModule } from '@/store/organizations';

export default defineComponent({
  name: 'OrgSelect',
  setup() {
    return { ...useOrgManager(), FetchStates };
  },
});
